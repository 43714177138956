import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './login.module.css'
import logo from '../../@assets/logo.svg'
import edge from '../../@assets/login/edgeIcon.svg'
import { useFormik } from 'formik';
import * as yup from 'yup'
import { Form, Col, Button } from 'react-bootstrap'
import mainStyles from '../app.module.css'
import Footer from '../common/Footer'
import ReCAPTCHA from "react-google-recaptcha";
import request from '../../module/request';
import LoadingOverlay from 'react-loading-overlay-ts';

function Login() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [recapcha, setRecapcha] = useState(null);
  const onChangeCapcha = function (value) {
    setRecapcha(value);
  }
  const schema = yup.object().shape({
    username: yup.string().required('작성자를 입력하세요.'),
    email: yup
      .string()
      .required('이메일을 입력하세요.')
      .email('이메일을 정확히 입력하세요.'),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      email: ""
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (!recapcha) {
        alert("로봇이 아닙니다를 체크해주셔야 합니다.");
        return;
      }
      const params = {
        name_kor: values.username,
        email: values.email
      }
      setIsLoading(true);
      request.post("/api/userLogin.php", params).then((response) => {
        setIsLoading(false);
        const data = response.data;
        if (data.status === 200) {
          navigate(`/license-list?authToken=${data.authToken}`);
        } else if (data.status === 204) {
          alert("해당 정보로 작성된 데이터가 없습니다.");
          return;
        } else {
          alert("서버에러가 발생하였습니다. \n관리자에게 문의하여 주시기 바랍니다.");
          return;
        }
      });
    },
  });

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text='로딩중 입니다....'
    >
      <div className={mainStyles.wrapper}>

        <main className={mainStyles.main}>
          <section className={styles.wrapper}>
            <div className={styles.container}>

              <aside className={styles.info_desk}>
                <span>
                  <img src={logo} alt="" /> <p style={{ fontSize: "20px", fontWeight: "bold" }}>라이선스 초기화 신청 확인</p>
                </span>
                <p style={{ marginTop: "30px" }}>
                  작성하신 이메일과
                  작성자 이름으로 로그인 하시면 됩니다.
                </p>
                <span>
                  <img src={edge} alt="" />
                  로그인에 오류가 있으신 경우 02-857-0974로 문의 바랍니다.
                </span>
              </aside>

              <div className={styles.formBox_wrapper}>
                <div className={styles.formBox_container}>
                  <Form noValidate onSubmit={formik.handleSubmit}>
                    <Form.Group
                      as={Col}
                      controlId="validationFormik01"
                      style={{ width: '100%' }}
                    >
                      <Form.Label className={styles.label}>
                        작성자 &nbsp;<span style={{ color: '#E62D47' }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="username"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="작성자를 입력하세요."
                        style={{
                          borderRadius: 8,
                          width: '100%',
                          height: 50,
                        }}
                        className={(formik.errors.username) ? 'is-invalid' : ''}
                      />
                      {formik.touched.username ? (
                        <Form.Control.Feedback type="invalid">{formik.errors.username}</Form.Control.Feedback>
                      ) : null}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      controlId="validationFormik02"
                      style={{ width: '100%' }}
                    >
                      <Form.Label className={styles.label}>
                        이메일 주소 &nbsp;
                        <span style={{ color: '#E62D47' }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="이메일을 입력하세요."
                        style={{
                          borderRadius: 8,
                          width: '100%',
                          height: 50,
                        }}
                        className={(formik.errors.email) ? 'is-invalid' : ''}
                      />
                      {formik.touched.email ? (
                        <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
                      ) : null}
                    </Form.Group>

                    <ReCAPTCHA
                      sitekey="6Lcuk0geAAAAAMA7NKFNlH64lRgB0Ch2Ko7bBAG9"
                      className={styles.robot}
                      onChange={onChangeCapcha}
                    />

                    <Button
                      type="submit"
                      className={styles.btn}
                      style={{ backgroundColor: '#316bb9', borderRadius: 8 }}
                    >
                      조회하기
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </section>

        </main>
        <Footer />

      </div>
    </LoadingOverlay>
  )
}

export default Login
