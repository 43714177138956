import React, { useState, useEffect } from 'react';
import { useLocation, Link, useNavigate } from "react-router-dom";
import styles from './apply.module.css';
import {
  Card,
  Table,
  Row,
  Col
} from 'react-bootstrap';
import PaginationComponent from 'components/Pagination';
import mainStyles from '../app.module.css';
import ScrollToToap from '../module/ScrollToToap';
import Header from '../common/Header';
import Footer from '../common/Footer';
import request from '../../module/request';
import LoadingOverlay from 'react-loading-overlay-ts';
import moment from 'moment';

function LicenseList() {
  const navigate = useNavigate();
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('authToken');
  const max = 10;
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const onChangePage = function (currentPage) {
    getData(currentPage);
  }

  const getData = function (currentPage) {
    setPage(currentPage);
    const params = {
      page: currentPage,
      max: max,
      auth_token: token
    }
    setIsLoading(true);
    request.get("/api/userList.php", params).then((response) => {
      const data = response.data;
      if (data.status === 204) {
        // 잘못된 접근
        alert("잘못된 접근입니다. \n로그인 페이지로 이동합니다.");
        navigate("/login");
      } else if (data.status === 401) {
        // 인증 실패 
        alert("잘못된 접근입니다. \n로그인 페이지로 이동합니다.");
        navigate("/login");
      } else {
        setIsLoading(false);
        setCount(data.data.count);
        setData(data.data.list);
      }
    }).catch((error) => {
      console.log(error);
      alert("데이터 처리중 에러가 발생하였습니다. \n관리자에게 문의하여 주시기 바랍니다.");
    });
  }



  const lastModifiedDate = function (createDate, updateDate) {
    const dateFormat = 'YYYY-MM-DD HH:mm:ss'
    if (updateDate) {
      return moment(updateDate).format(dateFormat)
    }
    if (createDate) {
      return moment(createDate).format(dateFormat)
    } else {
      return ''
    }
  }

  const convertToStatus = function (status) {
    let str = ""
    if (status === 0) {
      str = "대기"
    } else if (status === 1) {
      str = "승인"
    } else if (status === 2) {
      str = "거부"
    } else if (status === 3) {
      str = "전송중"
    } else if (status === 4) {
      str = "완료"
    } else if (status === 5) {
      str = "초기화 신청 재 전송"
    } else if (status === 6) {
      str = "취소"
    }
    return str
  }

  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    getData(1);
  }, []);

  const isEmpty = (count === 0);
  let items
  if (isEmpty) {
    items = <tr><td colSpan={10} style={{ textAlign: "center" }} >데이터가 없습니다.</td></tr>
  } else {
    items = data.map((obj, key) => {
      return <tr key={key}>
        <td style={{ textAlign: "center" }} >{obj.ID}</td>
        <td style={{ textAlign: "center" }} ><Link style={{ textDecoration: "underline", color: "#4b70fd" }} to={`/password?id=${obj.ID}`}>{obj.COMPANY_NAME_KOR} ({obj.COMPANY_NAME_ENG})</Link></td>
        <td style={{ textAlign: "center" }} >{obj.NAME_KOR}</td>
        <td style={{ textAlign: "center" }} >{convertToStatus(obj.STATUS)}</td>
        <td style={{ textAlign: "center" }} >{lastModifiedDate(obj.UPDATE_DATE, obj.REG_DATE)}</td>
      </tr>
    });
  }

  return (
    <div className={mainStyles.wrapper}>
      <ScrollToToap />
      <Header />
      <main className={mainStyles.main}>
        <section className={styles.wrapper}>
          <div className={styles.containerTable}>
            <LoadingOverlay
              active={isLoading}
              spinner
              text='로딩중 입니다....'
            >
              <Card>
                <Card.Header><strong>처리 현황</strong></Card.Header>
                <Card.Body style={{ paddingLeft: "5px", paddingRight: "5px", paddingTop: "30px" }}>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th style={{ textAlign: "center" }} >ID</th>
                        <th style={{ textAlign: "center" }} >회사명</th>
                        <th style={{ textAlign: "center" }} >작성자</th>
                        <th style={{ textAlign: "center" }} >처리현황</th>
                        <th style={{ textAlign: "center" }} >최종 수정일</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items}
                    </tbody>
                  </Table>
                </Card.Body>
                <Card.Footer>
                  <Row>
                    <Col xs={5}>
                      ( <strong>총</strong> : {count} 행,  <strong>현재 페이지</strong> {page} / {Math.ceil(count / max)} Pages)
                    </Col>
                    <Col md={7} >
                      <PaginationComponent currentPage={page} totalElements={count} onChangePage={onChangePage} />
                    </Col>
                  </Row>

                </Card.Footer>
              </Card>
            </LoadingOverlay>
          </div>
        </section>
      </main>
      <Footer />
    </div >

  )
}

export default LicenseList
