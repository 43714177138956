import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import styles from './apply.module.css'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Form, Col, Button, Row } from 'react-bootstrap'
import warning from '../../@assets/apply/warning_icon.svg'
import upIcon from '../../@assets/apply/upIcon.svg'
import downIcon from '../../@assets/apply/downIcon.svg'
import mainStyles from '../app.module.css'
import ScrollToToap from '../module/ScrollToToap'
import Header from '../common/Header'
import Footer from '../common/Footer'
import { useReactToPrint } from 'react-to-print';
import printHeader from '../../@assets/print/print-header.png';
import printFooter from '../../@assets/print/print-footer.png';
import request from '../../module/request';
import LoadingOverlay from 'react-loading-overlay-ts';
import tempFile from '../../@assets/files/GstarCAD_use_guild.pdf';
import './bootstrap-override.css';

const PrintPage = (props) => {
  const { printRef, form, uuids, reasonList, returnReason, reactivateSameCheck } = props;

  const items = uuids.map((obj, key) => {
    return (<span key={key}>
      {obj.value} <br />
    </span>)
  });

  const reasonItem = reasonList.find(obj => Number(obj.ID) === Number(returnReason))
  return (
    <div style={{ display: "none" }}>
      <div ref={printRef} style={{ padding: '0', margin: '0' }}>
        <div style={{ position: "fixed", top: "0", width: "100%", height: "40px" }}>
          <img
            style={{ width: "100%" }}
            src={printHeader}
            alt="header"
          />
        </div>
        <div id="main" style={{ padding: "100px 40px" }}>
          <h2 style={{ textAlign: "center" }}>Report of Return License</h2>
          <div style={{ marginTop: "30px", fontWeight: "bold" }}>
            Country(국가): KOREA (한국)
          </div>
          <div style={{ marginTop: "30px", fontWeight: "bold" }}>
            Company(<span style={{ color: "red" }}>회사명_영문 작성</span>):
            {form.companyNameEng}
          </div>
          <div style={{ marginTop: "30px", fontWeight: "bold" }}>
            Contact(연락처): {form.contact}
          </div>

          <div style={{ marginTop: "30px", fontWeight: "bold" }}>
            E-mail(이메일): {form.email}
          </div>

          <div style={{ marginTop: "30px", fontWeight: "bold" }}>
            Position(주소): {form.address}
          </div>

          <div style={{ marginTop: "30px", fontWeight: "bold" }}>
            The reason why can't generate a return code/file(리턴을 하지
            못한 이유): <br />
            {reasonItem ? reasonItem.INFO_CODE : ''}
          </div>

          <div style={{ marginTop: "30px", fontWeight: "bold" }}>
            Serial Number(시리얼 넘버): <br />
            <span>
              {form.uuid} <br />
              {items}
            </span>
          </div>

          <div style={{ marginTop: "30px", fontWeight: "bold" }}>
            Will Re-activate it in the Same PC (Yes/No) <br />
            동일한 장비에서 다시 활성화 합니다(예 / 아니오): &nbsp;
            {(reactivateSameCheck === "0") ? 'YES' : 'NO'}

          </div>

          <div style={{ marginTop: "30px", fontWeight: "bold" }}>
            Company Signature: (직인 날인):
          </div>

          <div style={{ backgroundColor: "#333", width: "100%", bottom: "0", position: "fixed" }}>
            <img style={{ width: "100%", margin: "0 auto", left: "0", bottom: "0", position: "fixed" }} src={printFooter} alt="footer" />
          </div>
        </div>
      </div>
    </div >
  );
}

const checkExt = function (ext) {
  const allowExt = ["jpg", "jpeg", "png", "pdf", "JPG", "JPEG", "PNG", "PDF"];
  return allowExt.find((e) => e === ext);
}

const getFileExt = function (fileName) {
  return (
    fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length) ||
    fileName
  );
}

function DetailPage() {
  const navigate = useNavigate();
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('authToken');
  const [id, setId] = useState(0);
  const [isRead, setIsRead] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reactivateSameCheck, setReactiveSameCheck] = useState("0");
  const [uuids, setUuids] = useState([]);
  const [firstUUid, setFirstUUid] = useState({});
  const [saveForm, setSaveForm] = useState({});
  const [disablePrintButton, setDisablePrintButton] = useState(true);
  const [disapproval, setDisapproval] = useState("");
  const [disapprovalTxt, setDisapprovalTxt] = useState("");
  const [status, setStatus] = useState("");
  const [reasonList, setReasonList] = useState([
    {
      ID: "1", INFO_CODE: "기존에 사용하던 PC에서 하단 SN 사용불가"
    },
    {
      ID: "2", INFO_CODE: "새로운 SN발급"
    },
    {
      ID: "3", INFO_CODE: "SN갱신"
    }
  ]);

  const [returnReason, setReaturnReason] = useState("1");
  const [reactiveFile, setReactiveFile] = useState();
  const [buninessFile, setBusinessFile] = useState();

  const [reactiveFilePath, setReactiveFilePath] = useState();
  const [businessFilePath, setBusinessFilePath] = useState();
  const [isCheckLicense] = useState(true);

  const getData = function () {
    request.get("/api/userDetail.php", { auth_token: token }).then((response) => {
      const data = response.data;
      if (data.status === 401) {
        alert("인증시간이 종료 되었습니다.\n인증을 다시 해주시기 바랍니다.");
        navigate(`/login`);
      } else if (data.status === 204 || data.status === 206) {
        alert("잘못된 접근 입니다.\n인증을 다시 해주시기 바랍니다.");
        navigate(`/login`);
      } else if (data.status === 200) {
        // 성공
        setReasonList(data.data.returnReason);
        const uuid = [];
        for (const index in data.data.serials) {
          const item = data.data.serials[index];
          if (index === "0") {
            formik.setFieldValue("uuid", item.SERIAL_NUMBER);
          } else {
            uuid.push({
              value: item.SERIAL_NUMBER,
              error: false
            });
          }
        }
        setUuids(uuid);
        const obj = data.data.obj;
        formik.setFieldValue("companyNameKor", obj.COMPANY_NAME_KOR);
        formik.setFieldValue("nameKor", obj.NAME_KOR);
        formik.setFieldValue("password", obj.PASSWORD);

        formik.setFieldValue("companyNameEng", obj.COMPANY_NAME_ENG);
        formik.setFieldValue("contact", obj.CONTACT);
        formik.setFieldValue("email", obj.EMAIL);

        formik.setFieldValue("address", obj.ADDRESS);
        formik.setFieldValue("reactivateSameCheck", obj.REACTIVATE_SAME_CHECK);
        setReactiveSameCheck(obj.REACTIVATE_SAME_CHECK.toString());
        setReaturnReason(obj.RETURN_REASON);
        setStatus(obj.STATUS);

        const disapTxt = data.data.disaproval.filter(dt => dt.ID === obj.DISAPROVAL_REASON_ID);
        if (disapTxt) {
          if (disapTxt.length > 0) {
            setDisapproval(disapTxt[0].INFO_CODE);
          }
        }
        setDisapprovalTxt(obj.DISAPROVAL_REASON_TEXT);
        setId(obj.ID);
      } else {
        alert("데이터 처리중 에러가 발생하였습니다. \n관리자에게 문의하여 주시기 바랍니다.");
      }

    }).catch((error) => {
      console.log(error);
      alert("데이터 처리중 에러가 발생하였습니다. \n관리자에게 문의하여 주시기 바랍니다.");
    });
  }

  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    getData()
  }, [])

  const componentRef = useRef(null);
  const handleChange = function (event) {
    setReactiveSameCheck(event.target.value);
  }

  const handleChangeUUID = function (e, obj) {
    obj.value = e.target.value;
    obj.status = 'before';
    const regexSerial = /^[A-Z0-9-]*$/;
    if (!regexSerial.test(e.target.value)) {
      obj.error = true;
      setUuids([...uuids]);
      return;
    }

    if (e.target.value.length !== 39) {
      obj.error = true;
      setUuids([...uuids]);
      return;
    }

    obj.error = false;
    setUuids([...uuids]);

  }

  const onChangeUUIDOne = function () {
    setFirstUUid({
      status: "before"
    })
  }

  const addElement = function () {
    setUuids([...uuids, {
      value: "",
      error: false
    }])
  }

  const modify = function (e) {
    e.preventDefault();
    if (window.confirm("해당 내용을 수정할수 있게 변경하시겠습니까?")) {
      setIsRead(false);
    }
  }

  const changeReason = function (event) {
    setReaturnReason(event.target.value);
  }

  const subElement = function (obj, index) {
    const picUuid = [uuids[index]]
    const subUuids = uuids.filter(item => !picUuid.includes(item))
    setUuids(subUuids)
  }

  const convertToStatus = function (status) {
    let str = ""
    if (status === 0) {
      str = "대기"
    } else if (status === 1) {
      str = "승인"
    } else if (status === 2) {
      str = "거부"
    } else if (status === 3) {
      str = "전송중"
    } else if (status === 4) {
      str = "완료"
    } else if (status === 5) {
      str = "초기화 신청 재 전송"
    } else if (status === 6) {
      str = "취소"
    }
    return str
  }

  const convertToLicenseStatus = function (status) {
    if (status === 'invalid') {
      return "이 시리얼 넘버는 무효한 넘버이오니 (주) 모두솔루션으로 문의 바랍니다.";
    } else if (status === 'expired') {
      return "이 시리얼 넘버는 기간 만료된 넘버이오니 더 이상 사용 불가 합니다.";
    } else if (status === 'initialized') {
      return "입력하신 시리얼 넘버는 사용가능한 상태이오니 초기화 하지 않으셔도 됩니다.";
    } else if (status === 'activated') {
      return "정상적인 시리얼 넘버입니다.";
    } else if (status === 'reseted') {
      return "이 시리얼 넘버는 이미 초기화 된 상태이니 바로 사용 가능합니다.";
    } else if (status === 'returned') {
      return "이 시리얼 넘버는 이미 리턴을 한 상태이오니 바로 사용 가능합니다.";
    } else if (status === 'encError') {
      return "이 시리얼 넘버는 동글 USB넘버로서 초기화 할수 없습니다. \n동글 USB는 장비에 꽂으면 바로 사용가능하오니 참고 바랍니다.";
    }
  }

  const handleChangeFile = function (event) {
    const fileName = event.target.files[0].name;
    const file = event.target.files[0];
    const ext = getFileExt(fileName);
    if (!checkExt(ext)) {
      alert("사업자 등록증, 재활성화 파일의 확장자를 체크하여 주시기 바랍니다.\nPDF,  PNG, JPG, JPEG 만 허용합니다.");
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    request.upload_post("/api/fileUpload.php", formData).then((response) => {
      setIsLoading(false);
      const data = response.data;
      if (data.status === 200) {
        const filePath = data.data.filePath;
        if (event.target.name === 'businessFile') {
          setBusinessFilePath(filePath);
          setBusinessFile(fileName);
        } else {
          setReactiveFilePath(filePath);
          setReactiveFile(fileName);
        }
      } else {
        alert("파일 처리중 에러가 발생하였습니다. \n관리자에게 문의하여 주시기 바랍니다.");
      }
    }).catch((error) => {
      console.log(error);
      alert("파일 처리중 에러가 발생하였습니다. \n관리자에게 문의하여 주시기 바랍니다.");
      setIsLoading(false);
    });
  }

  const form = yup.object().shape({
    companyNameKor: yup.string().required('회사명은 필수입니다.'),
    nameKor: yup.string().required('작성자명은 필수입니다.'),
    password: yup.string()
      .required('비밀번호는 필수 입니다.')
      .matches(
        /^[0-9]{4}$/,
        "비밀번호는 4자리 숫자입니다."
      ),
    companyNameEng: yup.string()
      .required('회사명(영문)은 필수 입니다.')
      .matches(
        /^[a-zA-Z0-9_ -.,]*$/,
        "회사명(영문)은 숫자,특수문자[공백포함](-_.,),영문 대/소문자만 포함할수 있습니다."
      ),
    contact: yup.string()
      .required('연락처는 필수 입니다.')
      .matches(
        /^[0-9-]*$/,
        "연락처는 숫자와 특수문자(-)만 포함합니다."
      ),
    email: yup.string()
      .required('이메일은 필수 입니다.')
      .matches(
        /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
        "이메일의 형식을 체크하여 주시기 바랍니다."
      ),
    address: yup.string().required('주소는 필수 입니다.'),
    uuid: yup.string()
      .required('시리얼 넘버는 필수 입니다.')
      .matches(
        /^([0-9, A-Z]{4})-([0-9, A-Z]{4})-([0-9, A-Z]{4})-([0-9, A-Z]{4})-([0-9, A-Z]{4})-([0-9, A-Z]{4})-([0-9, A-Z]{4})-([0-9, A-Z]{4})$/,
        "시리얼 넘버의 형식이 맞지 않습니다."
      )
      .min(39, "시리얼 넘버의 형식이 맞지 않습니다.")
      .max(39, "시리얼 넘버의 형식이 맞지 않습니다."),
  })

  const formik = useFormik({
    initialValues: {
      companyNameKor: "",
      nameKor: "",
      password: "",
      companyNameEng: "",
      contact: "",
      email: "",
      address: "",
      reactivateSameCheck: 0,
      uuid: "",
    },
    validationSchema: form,
    onSubmit: (values) => {
      if (disablePrintButton) {
        if (window.confirm("라이선스 처리를 저장하시겠습니까?")) {
          const regex = new RegExp(/^[A-Z0-9-]*$/);
          const serialNumbers = [];
          serialNumbers.push(values.uuid);
          for (let i = 0; i < uuids.length; i++) {
            const serialNumber = uuids[i].value;
            if (!regex.test(serialNumber)) {
              alert("라이선스 넘버의 검증이 실패하였습니다.");
              return;
            }
            if (serialNumber.length !== 39) {
              alert("라이선스 넘버의 검증이 실패하였습니다.");
              return;
            }
            serialNumbers.push(serialNumber);
          }
          setIsLoading(true);
          request.get("/api/userCheckLicense.php", { "license": serialNumbers.join(";") }).then((response) => {
            setIsLoading(false);
            const data = response.data;
            const checkedLicense = data.data;

            if (checkedLicense.length > 0) {
              const firstLicense = checkedLicense[0];
              if (firstLicense?.errorcode === "0") {
                setFirstUUid({
                  "status": firstLicense?.licenseStatus
                });
              } else {
                setFirstUUid({
                  "status": 'invalid'
                });
              }
              const arr = []
              checkedLicense.forEach((value, index) => {
                if (index === 0) {
                  return;
                }
                const checkValue = value;
                const serial = uuids[index - 1];
                if (checkValue.errorcode === "0") {
                  serial.status = checkValue.licenseStatus;
                  if (Number(serial.encryptionType) === 4 || Number(serial.encryptionType) === 5) {
                    serial.status = "encError";
                  }
                } else {
                  serial.status = "invalid";
                }

                arr.push(serial);
              });
              setUuids(arr)

              const checkLen = checkedLicense.filter(item => item.licenseStatus === 'activated')?.length;
              if (Number(checkLen) !== Number(checkedLicense.length)) {
                if (isCheckLicense) {
                  alert("신청하신 시리얼 넘버를 다시한번 확인 부탁드립니다.");
                  // setCheckLicense(false);
                  return;
                }
              }

              let status = firstLicense?.licenseStatus;
              if (Number(firstLicense?.encryptionType) === 4 || Number(firstLicense?.encryptionType) === 5) {
                status = "encError";
              }
              if (status !== 'activated') {
                if (isCheckLicense) {
                  alert("신청하신 시리얼 넘버를 다시한번 확인 부탁드립니다.");
                  // setCheckLicense(false);
                  return;
                }
              }


              setSaveForm(values);
              setDisablePrintButton(false);
              alert("저장에 성공하였습니다.\n문서를 출력하여 직인 날인 후 사업자사본이랑 각각 첨부해 주시기 바랍니다.");
            }
          }).catch((error) => {
            setIsLoading(false);
            console.log("error : ", error);
            setSaveForm(values);
            setDisablePrintButton(false);
            alert("저장에 성공하였습니다.\n문서를 출력하여 직인 날인 후 사업자사본이랑 각각 첨부해 주시기 바랍니다.");
          });
        }
      } else {

        if (!businessFilePath) {
          alert("사업자 사본을 첨부하여 주시기 바랍니다.");
          return;
        }
        if (!reactiveFilePath) {
          alert("초기화 문서를 첨부하여 주시기 바랍니다.");
          return;
        }

        const regex = new RegExp(/^[A-Z0-9-]*$/);
        const serialNumbers = [];
        serialNumbers.push(values.uuid);
        for (let i = 0; i < uuids.length; i++) {
          const serialNumber = uuids[i].value;
          if (!regex.test(serialNumber)) {
            alert("라이선스 넘버의 검증이 실패하였습니다.");
            return;
          }
          if (serialNumber.length !== 39) {
            alert("라이선스 넘버의 검증이 실패하였습니다.");
            return;
          }
          serialNumbers.push(serialNumber);
        }

        if (window.confirm("재 활성화 폼을 다시 제출하시겠습니까?")) {
          const params = {
            id: id.toString(),
            companyNameEng: values.companyNameEng,
            companyNameKor: values.companyNameKor,
            nameKor: values.nameKor,
            password: values.password,
            contact: values.contact,
            email: values.email,
            address: values.address,
            returnReason: returnReason,
            reactivateSameCheck: reactivateSameCheck,
            reactivateFile: reactiveFilePath,
            businessFile: businessFilePath,
            serialNumber: serialNumbers,
          };
          setIsLoading(true);
          request.post("/api/userInfoUpdate.php", params).then((response) => {
            setIsLoading(false);
            const data = response.data;
            if (data.status === 200) {
              alert("성공적으로 제출하였습니다\n처리하는데 1~2영업일이 소요되며, 처리완료후 메일로 안내 드립니다.");
              navigate('/login');
            } else {
              alert("재 활성화 폼 수정중 에러가 발생하였습니다. \n관리자에게 문의하여 주시기 바랍니다.");
            }
          }).catch((error) => {
            console.log(error);
            alert("재활성화 수정중 에러가 발생하였습니다. \n관리자에게 문의하여 주시기 바랍니다.");
            setIsLoading(false);
          });
        }
      }
    }
  });

  const handlePrint = useReactToPrint({
    documentTitle: `GstarCAD Certificate_${formik.values.companyNameEng}`,
    content: () => componentRef.current,
  });

  const items = uuids.map((obj, key) => {
    return <div className={styles.serial_wrap} key={key}>
      <div className={styles.serial_box}>
        <Form.Control
          type="text"
          name={key}
          onChange={(e) => handleChangeUUID(e, obj)}
          onBlur={(e) => handleChangeUUID(e, obj)}
          value={obj.value}
          placeholder="시리얼 넘버를 입력하세요."
          readOnly={isRead}
          style={{
            borderRadius: 8,
            height: 50,
            borderColor: (obj?.status === 'initialized'
              || obj?.status === 'activated'
              || obj?.status === 'reseted'
              || obj?.status === 'returned') && '#000',
            color:
              (obj?.status === 'initialized'
                || obj?.status === 'reseted'
                || obj?.status === 'returned'
                || obj?.status === 'encError') ? '#198754' :
                (obj?.status === 'invalid'
                  || obj?.status === 'expired') ? '#dc3545' :
                  (obj?.status === 'activated') && '#000',
          }}
          className={obj.error ? 'is-invalid' : ''}
          isInvalid={
            (obj?.status === 'invalid'
              || obj?.status === 'expired') ? true : false}
          isValid={
            (obj?.status === 'initialized'
              || obj?.status === 'reseted'
              || obj?.status === 'returned'
              || obj?.status === 'encError') ? true : false}
        />
        {
          obj.error ? (
            <Form.Control.Feedback type="invalid">시리얼 넘버를 확인하여 주시기 바랍니다.</Form.Control.Feedback>
          ) : (null)
        }
        {
          (obj?.status === 'invalid'
            || obj?.status === 'expired') &&
          <Form.Control.Feedback type="invalid">{convertToLicenseStatus(obj?.status)}</Form.Control.Feedback>
        }
        {
          (obj?.status === 'initialized'
            || obj?.status === 'reseted'
            || obj?.status === 'returned'
            || obj?.status === 'encError') &&
          <Form.Control.Feedback>{convertToLicenseStatus(obj?.status)}</Form.Control.Feedback>
        }
        {
          (obj?.status === 'activated') &&
          <div className="black-text">{convertToLicenseStatus(obj?.status)}</div>
        }
      </div>
      <div className={styles.serialBtn_box}>
        <button className={styles.serialBtn1} type="button" onClick={() => subElement(obj, key)}>
          <img src={downIcon} alt="" />
        </button>
      </div>
    </div>
  });

  const reasonItems = reasonList.map((obj, key) => {
    return <option value={obj.ID} key={key}>{obj.INFO_CODE}</option>
  });

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text='로딩중 입니다....'
    >
      <div className={mainStyles.wrapper}>
        <ScrollToToap />
        <Header />
        <main className={mainStyles.main}>
          <section className={styles.wrapper}>
            <Form noValidate onSubmit={formik.handleSubmit} className={styles.container}>

              {/* ========= 작성 가이드 보기 박스 ========= */}
              <div className={styles.guide_download_box} style={{ marginTop: "0px", marginBottom: "0px" }}>
                <p><strong>작성 내용 수정</strong></p>
                <a href={tempFile}><span>작성 가이드 다운로드</span></a>
                <p>아래 항목의 정보를 올바르게 기입한 후 <strong style={{ backgroundColor: "yellow" }}>문서 출력하기 클릭 후 문서 마지막 항목에 회사 직인을 날인하세요.</strong></p>
                <div style={{ color: "#666666" }}>(직인이 없으면 정상적으로 초기화 신청이 되지 않습니다.)</div>
                <div style={{ color: "#666666" }}>개인 명의 구매자 이신 경우 사업자 등록증 란에 구매자의 신분증을 첨부하여 주시기 바랍니다.</div>
              </div>

              <div className={styles.guide_download_box}>
                <p><strong>작성 내용 상태</strong></p>
                <div>
                  <strong style={{ color: '#E62D47' }}>*</strong> 상태 : <strong>{convertToStatus(status)}</strong>  <br />
                </div>
                {status === 2 ?
                  <div className={styles.textFild} style={{ height: "100px", marginTop: "10px" }}>
                    <strong style={{ color: '#E62D47' }}>*</strong> 미 승인 사유 <br />
                    <strong>{disapproval}</strong> <br />
                    <div>{disapprovalTxt}</div>
                  </div>
                  : (null)}
              </div>

              {/* ========= 회원정보 필드 ========= */}
              <div className={styles.kor_field}>
                <Form.Group as={Col} >
                  <Form.Label className={styles.label}>
                    회사명(한글) &nbsp;
                    <span style={{ color: '#E62D47' }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="companyNameKor"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.companyNameKor}
                    placeholder="한글 표기 회사명을 한글 입력하여 주세요."
                    readOnly={isRead}
                    style={{
                      borderRadius: 8,
                      height: 50,
                    }}
                    className={(formik.errors.companyNameKor) ? 'is-invalid' : ''}
                  />
                  {formik.touched.companyNameKor ? (
                    <Form.Control.Feedback type="invalid">{formik.errors.companyNameKor}</Form.Control.Feedback>
                  ) : null}

                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label className={styles.label}>
                    작성자(한글) &nbsp;
                    <span style={{ color: '#E62D47' }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="nameKor"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.nameKor}
                    placeholder="한글로 된 작성자의 성함을 입력하여 주세요."
                    readOnly={isRead}
                    style={{
                      borderRadius: 8,
                      height: 50,
                    }}
                    className={(formik.errors.nameKor) ? 'is-invalid' : ''}
                  />
                  {formik.touched.nameKor ? (
                    <Form.Control.Feedback type="invalid">{formik.errors.nameKor}</Form.Control.Feedback>
                  ) : null}
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label className={styles.label}>
                    비밀번호(4자리의 숫자형식) &nbsp;
                    <span style={{ color: '#E62D47' }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    placeholder="비밀번호를 입력하여 주세요."
                    disabled
                    style={{
                      borderRadius: 8,
                      height: 50,
                    }}
                    className={(formik.errors.password) ? 'is-invalid' : ''}
                  />
                  {
                    formik.touched.password ? (
                      <Form.Control.Feedback type="invalid">{formik.errors.password}</Form.Control.Feedback>
                    ) : null
                  }
                </Form.Group>
              </div>

              {/* ========= Report of Return License 필드 ========= */}
              <h1>Report of Return License</h1>
              <div className={styles.eng_field}>
                <div className={styles.eng_field_container}>
                  <Form.Group as={Col}>
                    <Form.Label className={styles.label}>
                      Company (회사명_영문 작성) &nbsp;
                      <span style={{ color: '#E62D47' }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="companyNameEng"
                      value={formik.values.companyNameEng}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder="회사명을 영문 입력하여 주세요."
                      readOnly={isRead}
                      style={{
                        borderRadius: 8,
                        height: 50,
                      }}
                      className={(formik.errors.companyNameEng) ? 'is-invalid' : ''}
                    />
                    {
                      formik.touched.companyNameEng ? (
                        <Form.Control.Feedback type="invalid">{formik.errors.companyNameEng}</Form.Control.Feedback>
                      ) : null
                    }
                  </Form.Group>

                  <Form.Group as={Col} style={{ marginTop: 10 }}>
                    <Form.Label className={styles.label}>
                      Contact(연락처)  &nbsp;
                      <span style={{ color: '#E62D47' }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="contact"
                      value={formik.values.contact}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder="연락처를 입력하여 주세요."
                      readOnly={isRead}
                      style={{
                        borderRadius: 8,
                        height: 50,
                      }}
                      className={(formik.errors.contact) ? 'is-invalid' : ''}
                    />
                    {
                      formik.touched.contact ? (
                        <Form.Control.Feedback type="invalid">{formik.errors.contact}</Form.Control.Feedback>
                      ) : null
                    }
                  </Form.Group>

                  <Form.Group as={Col} style={{ marginTop: 10 }}>
                    <Form.Label className={styles.label}>
                      Email(이메일)  &nbsp;
                      <span style={{ color: '#E62D47' }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      value={formik.values.email}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder="이메일을 입력하여 주세요."
                      readOnly={isRead}
                      style={{
                        borderRadius: 8,
                        height: 50,
                      }}
                      className={(formik.errors.email) ? 'is-invalid' : ''}
                    />
                    {
                      formik.touched.email ? (
                        <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
                      ) : null
                    }
                  </Form.Group>

                  <Form.Group as={Col} style={{ marginTop: 10 }}>
                    <Form.Label className={styles.label}>
                      Position(주소) &nbsp;
                      <span style={{ color: '#E62D47' }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="address"
                      value={formik.values.address}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder="주소를 입력하여 주세요."
                      readOnly={isRead}
                      style={{
                        borderRadius: 8,
                        height: 50,
                      }}
                      className={(formik.errors.address) ? 'is-invalid' : ''}
                    />
                    {
                      formik.touched.address ? (
                        <Form.Control.Feedback type="invalid">{formik.errors.address}</Form.Control.Feedback>
                      ) : null
                    }
                  </Form.Group>

                  <Form.Group
                    as={Col}

                    style={{ marginTop: 10 }}
                  >
                    <Form.Label className={styles.label}>
                      The reason why can't generate a return code/file (리턴을
                      하지 못한 이유) &nbsp;
                      <span style={{ color: '#E62D47' }}>*</span>
                    </Form.Label>

                    <Form.Select
                      aria-label="Default select example"
                      style={{
                        borderRadius: 8,
                        height: 50,
                      }}
                      name="returnReason"
                      value={returnReason}
                      onChange={changeReason}
                      disabled={isRead}
                    >
                      {reasonItems}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group
                    as={Col}

                    style={{ marginTop: 10 }}
                  >
                    <Form.Label className={styles.label}>
                      Serial Number (시리얼 넘버)&nbsp;
                      <span style={{ color: '#E62D47' }}>*</span>
                      <span style={{ color: '#E62D47' }}>(예시: 1234-XXXX-0000-XXXX-1234-XXXX-0000-0300) </span>
                    </Form.Label>

                    <div className={styles.serial_wrap}>
                      <div className={styles.serial_box}>
                        <Form.Control
                          type="text"
                          name="uuid"
                          value={formik.values.uuid}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          onKeyUp={() => onChangeUUIDOne()}
                          placeholder="시리얼 넘버를 입력하세요."
                          disabled={isRead}
                          style={{
                            borderRadius: 8,
                            height: 50,
                            borderColor: (firstUUid?.status === 'activated') && '#000',
                            color:
                              (firstUUid?.status === 'initialized'
                                || firstUUid?.status === 'reseted'
                                || firstUUid?.status === 'returned'
                                || firstUUid?.status === 'encError') ? '#198754' :
                                (firstUUid?.status === 'invalid'
                                  || firstUUid?.status === 'expired') ? '#dc3545' :
                                  (firstUUid?.status === 'activated') && '#000',
                          }}
                          className={(formik.errors.uuid) ? 'is-invalid' : ''}
                          isInvalid={(firstUUid?.status === 'invalid'
                            || firstUUid?.status === 'expired') ? true : false}
                          isValid={
                            (firstUUid?.status === 'initialized'
                              || firstUUid?.status === 'reseted'
                              || firstUUid?.status === 'returned'
                              || firstUUid?.status === 'encError') ? true : false}
                        />
                        {
                          formik.touched.uuid ? (
                            <Form.Control.Feedback type="invalid">{formik.errors.uuid}</Form.Control.Feedback>
                          ) : null
                        }

                        {
                          (firstUUid?.status === 'invalid'
                            || firstUUid?.status === 'expired') &&
                          <Form.Control.Feedback type="invalid">{convertToLicenseStatus(firstUUid?.status)}</Form.Control.Feedback>
                        }

                        {
                          (firstUUid?.status === 'initialized'
                            || firstUUid?.status === 'reseted'
                            || firstUUid?.status === 'returned'
                            || firstUUid?.status === 'encError') &&
                          <Form.Control.Feedback>{convertToLicenseStatus(firstUUid?.status)}</Form.Control.Feedback>
                        }

                        {
                          (firstUUid?.status === 'activated') &&
                          <div className="black-text">{convertToLicenseStatus(firstUUid?.status)}</div>
                        }
                      </div>
                      {(!isRead) ?
                        <div className={styles.serialBtn_box}>
                          <button className={styles.serialBtn2} type="button" onClick={() => addElement()}>
                            <img src={upIcon} alt="" />
                          </button>
                        </div>
                        : (null)}
                    </div>

                    {items}

                  </Form.Group>

                  <Form.Group
                    as={Col}
                    className={styles.radioGroup}
                  >
                    <Form.Label className={styles.label}>
                      Will Re-activate it in the Same PC(Yes/NO) 동일한 장비에서
                      다시 활성화 합니다. &nbsp;
                      <span style={{ color: '#E62D47' }}>*</span>
                    </Form.Label>

                    <div className={styles.radioBox}>
                      <Form.Check
                        inline
                        name="radio"
                        type="radio"
                        label="예"
                        id="radio1"
                        value="0"
                        checked={reactivateSameCheck === "0"}
                        disabled={isRead}
                        onChange={handleChange}
                        style={{ padding: 0 }}
                      />

                      <Form.Check
                        inline
                        name="radio"
                        type="radio"
                        value="1"
                        checked={reactivateSameCheck === "1"}
                        disabled={isRead}
                        onChange={handleChange}
                        label="아니오"
                        id="radio2"
                      />
                    </div>
                  </Form.Group>

                  {/* ========= 안내박스 필드 ========= */}
                  {status === 2 ?
                    <div className={styles.infodesk}>
                      <img src={warning} alt="" />
                      <p>
                        위 정보 입력 후 저장, 출력하여 회사직인 날인 후 초기화 문서 란에 첨부하여 사업자 사본과 같이 제출바랍니다.
                      </p>
                    </div>
                    : (null)}
                  {/* ========= 버튼 이슈 필드 =========  */}
                  {status === 2 ?
                    < div className={styles.subBtn_box}>
                      {(isRead) ?
                        (<button
                          type="button"
                          className={styles.subBtn}
                          style={{}}
                          onClick={modify}
                        >
                          수정하기
                        </button>)
                        :
                        (<button
                          type="submit"
                          className={styles.subBtn}
                          style={{}}
                        >
                          저장하기
                        </button>)
                      }
                      <button
                        type="button"
                        className={disablePrintButton ? styles.subBtn_disabled : styles.subBtn}
                        onClick={handlePrint} disabled={disablePrintButton}>
                        문서 출력하기
                      </button>
                    </div>
                    : (null)}
                  {/* ========= 파일첨부 여부 필드 =========  */}
                  {status === 2 ?
                    <Row>
                      <Col md={10}>
                        <div className={styles.fileBox}>
                          <div>
                            사업자 사본&nbsp;
                            <span style={{ color: '#E62D47' }}>*</span>
                          </div>

                          <span style={{ color: '#797979' }}>{(buninessFile) ? buninessFile : '파일첨부 없음'}</span>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className={styles.subBtn_box}>
                          <input type="file" name="businessFile" id={disablePrintButton ? 'none1' : 'input-file1'} accept=".pdf,.png,.jpg,.jpeg" style={{ display: "none" }} onChange={handleChangeFile} />
                          <label
                            type="button"
                            htmlFor="input-file1"
                            className={disablePrintButton ? styles.subBtn_disabled : styles.subBtn}
                          >
                            파일 첨부하기
                          </label>
                        </div>
                      </Col>
                    </Row>
                    : (null)}

                  {/* ========= 파일첨부 여부 필드 =========  */}
                  {status === 2 ?
                    <Row>
                      <Col md={10}>
                        <div className={styles.fileBox}>
                          <div>
                            초기화 문서&nbsp;
                            <span style={{ color: '#E62D47' }}>*</span>
                          </div>

                          <span style={{ color: '#797979' }}>{(reactiveFile) ? reactiveFile : '파일첨부 없음'}</span>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className={styles.subBtn_box}>
                          <input type="file" name="reactiveFile" id={disablePrintButton ? 'none2' : 'input-file2'} accept=".pdf,.png,.jpg,.jpeg" style={{ display: "none" }} onChange={handleChangeFile} />
                          <label
                            type="button"
                            htmlFor="input-file2"
                            className={disablePrintButton ? styles.subBtn_disabled : styles.subBtn}
                            style={{}}
                          >
                            파일 첨부하기
                          </label>
                        </div>
                      </Col>
                    </Row>
                    : (null)}
                  {status === 2 ?
                    <Button
                      className={styles.mainBtn}
                      type="submit"
                      style={{
                        borderColor: '#316bb9',
                        background: '#316bb9',
                        borderRadius: 0,
                      }}
                      disabled={disablePrintButton}>
                      다시 제출하기
                    </Button>
                    : (null)}
                </div>
              </div>

            </Form>
          </section>
        </main>
        <PrintPage printRef={componentRef} form={saveForm} uuids={uuids} reasonList={reasonList} returnReason={returnReason} reactivateSameCheck={reactivateSameCheck} />
        <Footer />
      </div >
    </LoadingOverlay >
  )
}

export default DetailPage;
