import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styles from './home.module.css'
import logo from '../../@assets/logo.svg'
import number1Icon from '../../@assets/home/number1.svg'
import number2Icon from '../../@assets/home/number2.svg'
import ingIcon1 from '../../@assets/home/ingIcon1.svg'
import ingIcon2 from '../../@assets/home/ingIcon2.svg'
import ingIcon3 from '../../@assets/home/ingIcon3.svg'
import flowIcon from '../../@assets/home/flowIcon.svg'
import guideIcon1 from '../../@assets/home/guideIcon1.svg'
import guideIcon2 from '../../@assets/home/guideIcon2.svg'
import nextIcon from '../../@assets/home/nextIcon.svg'
import linkIcon1 from '../../@assets/home/linkIcon1.svg'
import linkIcon2 from '../../@assets/home/linkIcon2.svg'
import warning from '../../@assets/apply/warning_icon.svg'
import book_guide from '../../@assets/home/book_guide.png'



import installGuide from '../../@assets/files/GstarCAD_guide.pdf';
import returnGuide from '../../@assets/files/return_Stand-alone.pdf';
import allGuide from '../../@assets/files/GstarCAD_All_guide.pdf';




import mainStyles from '../app.module.css';
import Footer from '../common/Footer';

import request from '../../module/request';

function Home() {
  const [step1, setStep1] = useState("")
  const [step2, setStep2] = useState("")

  const [requestData, setRequestData] = useState("")
  const [process, setProcess] = useState("")
  const [finish, setFinish] = useState("")

  const getData = function () {
    request.get("/api/userNotice.php", {}).then((response) => {
      const data = response.data.data.obj;
      setStep1(data.STEP1);
      setStep2(data.STEP2);
      setRequestData(data.REQUEST);
      setProcess(data.PROCESS);
      setFinish(data.FINISH);
    }).catch((error) => {
      console.log(error);
      alert("파일 처리중 에러가 발생하였습니다. \n관리자에게 문의하여 주시기 바랍니다.");
    });
  }
  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    getData()
  }, [])

  return (
    <div className={mainStyles.wrapper}>
      <main className={mainStyles.main}>
        <section className={styles.wrapper}>
          <div className={styles.container}>
            <div className={styles.comp_title}>
              <img src={logo} alt="" />
              <h1>라이선스 초기화 신청</h1>
            </div>

            <div className={styles.comp_wrap}>
              <div className={styles.comp_box}>
                {listMap.linkBox.map((item, key) => {
                  return (
                    <Link key={key} to={item.a} className={item.cName}>
                      {item.name}
                      <img src={item.icon} alt="" />
                    </Link>
                  )
                })}

                <div className={styles.guide_wrap} style={{ marginTop: "10px" }}>
                  <a href={allGuide} className={styles.guide_box}>
                    <img src={book_guide} width="50" alt="" />
                    <h3>사이트 이용 가이드</h3>
                    <span>
                      다운로드 <img src={nextIcon} alt="" />
                    </span>
                  </a>
                </div>


                <h2 className={styles.comp1_title}>안내</h2>
                <ul>
                  <li className={styles.info_box}>
                    <img src={number1Icon} alt="" />
                    <div dangerouslySetInnerHTML={{ __html: step1 }} />
                  </li>
                </ul>

                <ul>
                  <li className={styles.info_box}>
                    <img src={number2Icon} alt="" />
                    <div dangerouslySetInnerHTML={{ __html: step2 }} />
                  </li>
                </ul>

                <div className={styles.guide_wrap_non_margin}>
                  <img src={warning} alt="" />
                  <p>
                    PC 교체는 리턴 후 작업 하시면 됩니다.
                  </p>
                </div>

                <div className={styles.guide_wrap}>
                  <a href={installGuide} className={styles.guide_box}>
                    <img src={guideIcon1} alt="" />
                    <h3>설치 가이드</h3>
                    <span>
                      다운로드 <img src={nextIcon} alt="" />
                    </span>
                  </a>
                  <a href={returnGuide} className={styles.guide_box}>
                    <img src={guideIcon2} alt="" />
                    <h3>리턴 가이드</h3>
                    <span>
                      다운로드 <img src={nextIcon} alt="" />
                    </span>
                  </a>
                </div>

              </div>
              <div className={styles.comp_box}>
                <h2 className={styles.comp2_title}>진행절차</h2>
                <p className={styles.comp2_subTitle}>
                  라이선스 초기화 신청 시 처리될 진행절차입니다.
                </p>

                <ul className={styles.ing_wrap}>
                  <li className={styles.ing_box}>
                    <img src={ingIcon1} alt="" />
                    <div className={styles.textBox}>
                      <h4>
                        대기 <span>#신청</span>
                      </h4>
                      <div dangerouslySetInnerHTML={{ __html: requestData }} />
                    </div>
                  </li>

                  <div className={styles.flowBox}>
                    <img src={flowIcon} alt="" />
                  </div>

                  <li className={styles.ing_box}>
                    <img src={ingIcon2} alt="" />
                    <div className={styles.textBox}>
                      <h4>
                        처리중 <span>#처리</span>
                      </h4>
                      <div dangerouslySetInnerHTML={{ __html: process }} />
                    </div>
                  </li>

                  <div className={styles.flowBox}>
                    <img src={flowIcon} alt="" />
                  </div>

                  <li className={styles.ing_box}>
                    <img src={ingIcon3} alt="" />
                    <div className={styles.textBox}>
                      <h4>
                        완료 <span>#완료</span>
                      </h4>
                      <div dangerouslySetInnerHTML={{ __html: finish }} />
                    </div>
                  </li>

                  <div className={styles.flowBox}>
                    <img src={flowIcon} style={{ visibility: "hidden" }} alt="" />
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </section>

      </main>
      <Footer />
    </div>
  )
}

export default Home

export const listMap = {
  linkBox: [
    {
      a: '/apply-page',
      name: '초기화 신청',
      cName: `${styles.linkbox} ${styles.linkbox1}`,
      icon: linkIcon1,
    },
    {
      a: '/login',
      name: '신청 확인하기',
      cName: `${styles.linkbox} ${styles.linkbox2}`,
      icon: linkIcon2,
    },
  ],

  guideBox: [
    {
      a: '../../@assets/files/Flexnet_Stand-alone.pdf',
      name: '설치 가이드',
      img: guideIcon1,
    },
    {
      a: '#',
      name: '리턴 가이드',
      img: guideIcon2,
    },
  ],
}
