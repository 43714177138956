import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Home from './home/Home';
import Login from './login/Login';
import ApplyPage from './page/ApplyPage';
import DetailPage from './page/DetailPage';
import LicenseList from './license-list/license-list';
import Password from './login/Password';

function App() {
  return (
    <HashRouter>
        <Routes>
          <Route path="/login"  element={<Login />} />
          <Route path="/password"  element={<Password />} />
          <Route path="/license-list"  element={<LicenseList />} />
          <Route path="/apply-page"  element={<ApplyPage />} />
          <Route path="/detail-page"  element={<DetailPage />} />
          <Route path="*"  element={<Home />} />
        </Routes>
    </HashRouter>
  )
}

export default App
