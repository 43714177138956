import React, { useState, useEffect } from 'react'
import {
  Pagination
} from 'react-bootstrap';

const PaginationComponent = ({ currentPage, totalElements, onChangePage }) => {
  const [page, setPage] = useState(1)
  const max = 10
  useEffect(() => {
    setPage(currentPage)
  }, [currentPage])

  const setPageFunction = function (page) {
    onChangePage(page)
  }

  const getPager = function (totalElements, currentPage, pageSize) {
    const totalPages = Math.ceil(totalElements / pageSize)
    let startPage
    let endPage
    if (totalPages <= 10) {
      startPage = 1
      endPage = totalPages
    } else {
      if (currentPage <= 6) {
        startPage = 1
        endPage = 10
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9
        endPage = totalPages
      } else {
        startPage = currentPage - 5
        endPage = currentPage + 4
      }
    }

    const startIndex = (currentPage - 1) * pageSize
    const endIndex = Math.min(startIndex + pageSize - 1, totalElements - 1)
    const pager = []
    for (let i = startPage; i < endPage + 1; i++) {
      pager.push(String(i))
    }

    return {
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pager
    }
  }

  const pager = getPager(totalElements, page, max)
  const isPrev = pager.currentPage === 1 ? true : false
  const isNext = pager.currentPage === pager.totalPages

  return <>
    <Pagination style={{ float: "right" }}>
      <Pagination.Prev disabled={isPrev} onClick={() => setPageFunction(pager.currentPage - 1)} style={{ cursor: "pointer" }}>
        <span aria-hidden="true">&laquo;</span>
      </Pagination.Prev>
      {pager.pages.map((obj, index) =>
        <Pagination.Item key={index} style={{ cursor: "pointer" }} onClick={() => setPageFunction(Number(obj))} active={pager.currentPage === Number(obj)}>{obj}</Pagination.Item>
      )}

      <Pagination.Next aria-label="Next" disabled={isNext} onClick={() => setPageFunction(pager.currentPage + 1)} style={{ cursor: "pointer" }}>
        <span aria-hidden="true">&raquo;</span>
      </Pagination.Next>
    </Pagination>
  </>
}

export default PaginationComponent;
