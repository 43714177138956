import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './login.module.css'
import logo from '../../@assets/logo.svg'
import edge from '../../@assets/login/edgeIcon.svg'
import { useFormik } from 'formik';
import * as yup from 'yup'
import { Form, Col, Button } from 'react-bootstrap'
import mainStyles from '../app.module.css'
import Footer from '../common/Footer'
import request from '../../module/request';
import LoadingOverlay from 'react-loading-overlay-ts';

function Password() {
  const navigate = useNavigate();
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');
  const [isLoading, setIsLoading] = useState(false);
  const schema = yup.object().shape({
    password: yup.string().required('비밀번호는 필수입니다..'),
  });

  const formik = useFormik({
    initialValues: {
      password: ""
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const params = {
        password: values.password,
        id: id
      }
      setIsLoading(true);
      request.post("/api/userPasswordVerification.php", params).then((response) => {
        setIsLoading(false);
        const data = response.data;
        if (data.status === 200) {
          navigate(`/detail-page?authToken=${data.authToken}`);
        } else if (data.status === 204) {
          alert("잘못된 접근입니다. \n다시 접근하여 주시기 바랍니다.");
          navigate(`/`);
          return;
        } else if (data.status === 401) {
          alert("비밀번호가 잘못되었습니다. 다시 입력하여 주시기 바랍니다.");
          return;
        } else {
          alert("서버에러가 발생하였습니다. \n관리자에게 문의하여 주시기 바랍니다.");
          return;
        }
      });
    },
  });

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text='로딩중 입니다....'
    >
      <div className={mainStyles.wrapper}>

        <main className={mainStyles.main}>
          <section className={styles.wrapper}>
            <div className={styles.container}>

              <aside className={styles.info_desk}>
                <img src={logo} alt="" />
                <h1>비밀번호 확인</h1>
                <p>
                  비밀글 입니다. 확인 하시려면 비밀번호를 입력해 주세요.
                </p>
                <span>
                  <img src={edge} alt="" />
                  비밀번호를 잊어버리신 경우 02-857-0974로 문의 바랍니다.
                </span>
              </aside>

              <div className={styles.formBox_wrapper}>
                <div className={styles.formBox_container}>
                  <Form noValidate onSubmit={formik.handleSubmit}>
                    <Form.Group
                      as={Col}
                      controlId="validationFormik01"
                      style={{ width: '100%' }}
                    >
                      <Form.Label className={styles.label}>
                        비밀번호 &nbsp;<span style={{ color: '#E62D47' }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="비밀번호를 입력해 주세요"
                        style={{
                          borderRadius: 8,
                          width: '100%',
                          height: 50,
                        }}
                        className={(formik.errors.password) ? 'is-invalid' : ''}
                      />
                      {formik.touched.password ? (
                        <Form.Control.Feedback type="invalid">{formik.errors.password}</Form.Control.Feedback>
                      ) : null}
                    </Form.Group>

                    <Button
                      type="submit"
                      className={styles.btn}
                      style={{ backgroundColor: '#316bb9', borderRadius: 8, marginTop: "20px" }}
                    >
                      조회하기
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </section>

        </main>
        <Footer />

      </div>
    </LoadingOverlay>
  )
}

export default Password
