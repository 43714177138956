import axios from 'axios';

const instanceAxios = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
});


const methods = {
  post (url, params) {
    return instanceAxios.post(url, params)
  },
  upload_post(url, form) {
    return instanceAxios.post(url, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  get (url, params) {
    return instanceAxios.get(url, {params: params})
  },
  put (url, params) {
    return instanceAxios.put(url, params)
  },
  delete (url, params) {
    return instanceAxios.delete(url, params)
  }
}

export default methods
