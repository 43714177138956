import React from 'react'
import styles from './common.module.css'
import logo from '../../@assets/logo.svg'
import { Link } from 'react-router-dom'
function Header() {
  return (
    <>
      <header className={styles.apply_header}>
        <Link to="/">
          <img src={logo} alt="" />
        </Link>
        <h1 className={styles.apply_page_title}>라이선스 초기화 신청</h1>
      </header>
    </>
  )
}

export default Header
