import React from 'react'
import styles from './common.module.css'
import fLogo from '../../@assets/footerLogo.svg'

function Footer() {
  return (
    <footer>
      <div className={styles.container}>
        <img src={fLogo} alt="" />
        <p>
          (주) 모두솔루션
          <br /> 사업자등록번호 : 129-81-72861 | TEL : 02-857-0974
          <br />
          서울특별시 금천구 가산디지털2로 67, 제 20층 제 2002호 (가산동,
          에이스하이엔드 타워7차) <br /> <br />
          <span>copyright @ 2022 Modoo Solution. All rights reserved.</span>
        </p>
      </div>
    </footer>
  )
}

export default Footer
